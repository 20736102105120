<template> 


<div class="share flex gap-2">
<button v-on:click="shareTelegram(coinName, coinSymbol, coinTelegram, currentUrl, root, title, telegram)" class="w-full bg-blue-400 px-6 py-4 rounded font-bold text-white hover:bg-blue-500 hover:text-white text-white">
 
                        <i class="fab fa-telegram dark:text-white text-xl"></i> Telegram
                 
</button>

<button v-on:click="shareTwitter(coinName, coinSymbol, coinTwitter, currentUrl, root, title, twitter)" class="w-full bg-blue-400 px-6 py-4 rounded font-bold text-white hover:bg-blue-500 hover:text-white text-white">
  <i class="fab fa-twitter dark:text-white text-xl"></i> Twitter
</button>
</div>


</template>
<script>
export default {
    name: "ShareButtons",
data() {
    return {
      root: process.env.VUE_APP_ROOT,
      title: process.env.VUE_APP_TITLE,
      twitter: process.env.VUE_APP_TWITTER,
      telegram: process.env.VUE_APP_TELEGRAM,
      recaptcha: false
    }
  },
 
props: ["coinName", "coinSymbol", "coinTwitter", "coinTelegram", "currentUrl"],
methods: {
    shareTelegram: function (coinName, coinSymbol, coinTelegram, currentUrl, root, title, telegram) {
      //return window.location.href = 'https://twitter.com/intent/tweet?text=test&url=';
       window.open('https://t.me/share/url?text=' + coinName + ' (' + coinSymbol + ') is Listed on ' + title + ' Join ' + telegram + ' and ' + coinTelegram + ' and help ' + coinSymbol + ' grow by voting&url=' + root + currentUrl, '_blank');
    },
    shareTwitter: function (coinName, coinSymbol, coinTwitter, currentUrl, root, title, twitter) {
      
      //return window.location.href = 'https://twitter.com/intent/tweet?text=test&url=';
      window.open('https://twitter.com/intent/tweet?text=' + coinName + ' (' + coinSymbol + ') is Listed on ' + title + ' Follow ' + twitter + ' and ' + coinTwitter + ' and help ' + coinSymbol + ' grow by voting&url=' + root + currentUrl, '_blank');
    } 
}
}
</script>
<style></style>