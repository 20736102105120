<template> 
 <metainfo/>
  <div class="min-h-screen bg-gray-200 dark:bg-gray-800">
    <main id="coin" class="coin py-5" v-if="coin">
      <!--
  <input v-model="ogTitle" />
  <pre>{{ meta }}</pre>
  -->
      <div class="
          max-w-3xl
          mx-auto
          grid grid-cols-1
          lg:grid-cols-2 lg:grid-flow-col-dense
          gap-1
          lg:max-w-7xl
          bg-white
          dark:bg-gray-900
          rounded-lg
          shadow-sm
          py-10
        ">
        <!-- Description list-->
        <section class="col-span-2" aria-labelledby="applicant-information-title ">
          <div class="relative">
            
            <div class="px-4 sm:px-6">

               
        <div class="lg:flex md:flex flex-start sm:block sm:text-center sm:items-center space-x-5 pb-5 mb-5 border-b dark:border-gray-600">
          <div class="
              flex flex-shrink-0
              sm:text-center
              items-center
              justify-center
            ">
            <img class="w-16" :src="coin.image" alt="" />
          </div>

          <div class="text-left">
            <h1 class="
                font-bold
                lg:text-3xl
                text-2xl text-gray-900
                dark:text-white
                sm:text-center
                lg:text-left
                md:text-left
                text-center
              ">
              {{ coin.fullname }} <span class="py-1 px-3 bg-gray-500 rounded-lg font-medium ml-3 text-white">{{ coin.symbol }}</span>
            </h1>
            <!-- <div v-if="moment(coin.launched) > moment(date)"> -->
 

           
    
            <span class="text-left dark:text-white justify-start">{{coin.type}}</span>
       
<!-- 
            <div class="flex items-center text-gray-900 dark:text-white flex-wrap">
              <a class="mr-2 flex hover:text-green-500 items-center" :href="coin.website" target="_blank"
                rel="nofollow">
                <i class="fa fa-globe dark:text-white text-lg"></i>
                <span class="ml-2">Website</span>
              </a>

              <a class="mr-2 flex hover:text-green-500 items-center" :href="coin.telegram" target="_blank"
                rel="nofollow">
                <i class="fab fa-telegram dark:text-white text-lg"></i>
                <span class="ml-2">Telegram</span>
              </a>

              <a class="mr-2 flex hover:text-green-500 items-center" :href="coin.twitter" target="_blank"
                rel="nofollow">
                <i class="fab fa-twitter dark:text-white text-lg"></i>
                <span class="ml-2">Twitter</span>
              </a>

              <a class="mr-2 flex hover:text-green-500 items-center" :href="coin.discord" target="_blank"
                rel="nofollow">
                <i class="fab fa-discord dark:text-white text-lg"></i>
                <span class="ml-2">Discord</span>
              </a>
            </div>

            -->
          </div>
          
        
        </div>

     

     
              <h2 class="text-lg font-bold text-gray-900 dark:text-white mb-5">
                {{ coin.network }} Contract
              </h2>
               
               <div v-if="coin.type == 'Presale'" > 
              <div class="flex items-center text-gray-900 dark:text-white flex-wrap"> This project is in presale phase. Contract announced later. </div>
              </div>
              <div v-else> 
              <div class="flex items-center text-gray-900 dark:text-white flex-wrap"> {{ coin.contract }}</div>
              </div> 

              <dl class="
                  hidden
                  text-gray-900
                  dark:text-white
                  text-smpx-4
                  py-5
                  sm:px-6
                  flex
                  justify-between
                ">
                <dt>
                  Votes:
                  <span class="
                      bg-green-500
                      py-1
                      px-5
                      rounded
                      votes
                      font-bold
                      text-white
                    ">{{ coin.votes }}</span>
                </dt>

                <div v-if="coin.network.indexOf('BSC')">
                  <dt>
                    Network:
                    <span class="
                        bg-green-500
                        py-1
                        px-5
                        rounded
                        votes
                        font-bold
                        text-white
                      ">BSC</span>
                  </dt>
                </div>
                <div v-else>
                  <dt>
                    Network:
                    <span class="
                        bg-green-500
                        py-1
                        px-5
                        rounded
                        votes
                        font-bold
                        text-white
                      ">ETH</span>
                  </dt>
                </div>

                <dt>
                  Age:
                  <span class="
                      bg-green-500
                      py-1
                      px-5
                      rounded
                      votes
                      font-bold
                      text-white
                    ">{{ momentFromNow(coin.launched) }}</span>
                </dt>

                <dt>
                  Status:
                  <span class="
                      bg-green-500
                      py-1
                      px-5
                      rounded
                      votes
                      font-bold
                      text-white
                    ">{{ coin.status }}</span>
                </dt>
              </dl>
 
              <div class="sm:col-span-2 text-gray-900 dark:text-white mt-5  border-b dark:border-gray-600 mb-5">
                <h2 class="text-lg font-bold text-gray-900 dark:text-white">
                  Description
                </h2>

                <p class="mb-5 py-5">
                  {{ coin.description }}
                </p>
              </div>

              <h2 class="text-lg font-bold text-gray-900 dark:text-white mb-5">
                Vote for {{ coin.symbol }}!
              </h2>
              <p class="text-gray-900 dark:text-white">
                By voting for {{ coin.fullname }}, you will help
                {{ coin.symbol }} gain visibility and increase it's rank on
                {{ title }}
              </p>

              <form @submit.prevent="recaptcha = !recaptcha">
                <button type="submit" :class="
                    `w-full flex flex-row items-center justify-center border border-green-500 px-6 py-4 my-5 rounded font-bold  ${
                      verifiedCoins.some(
                        (verifiedCoin) => verifiedCoin.coinId === coin.id
                      )
                        ? 'bg-green-500 text-white'
                        : 'text-green-500 hover:bg-green-500 hover:text-white text-white'
                    }`
                  ">
                  Vote <i class="fa fa-arrow-up mx-2"></i> {{ coin.votes }}

                  <!-- {{verifiedCoins.some((verifiedCoin) => verifiedCoin.coinId === coin.id) ? "Voted" : "Vote"}} <i class="fa fa-arrow-up mx-2"></i>  {{coin.votes}} -->
                </button>
              </form>
              <em class="text-sm text-gray-900 dark:text-white">You can vote {{ coin.symbol }} every 24 hours</em>
              <hr class="my-5" />
   <h2 class="text-lg font-bold text-gray-900 dark:text-white mb-5">
                Share {{ coin.symbol }}!
              </h2>
              <ShareButtons :coinName="coin.fullname" :coinSymbol="coin.symbol"
                :coinTwitter="coin.twitter.replace('https://twitter.com/', '@')"
                :coinTelegram="coin.telegram.replace('https://t.me/', '@')" :currentUrl="this.$route.path" />
            </div>
          </div>
        </section>

        <section aria-labelledby="details-title" class="col-span-1 px-4 py-5 sm:px-6">
          <div class="mb-5">
            <h2 id="details-title" class="text-lg font-bold text-gray-900 dark:text-white">
              Details
            </h2>

            <!-- Activity Feed -->
            <div class="mt-6 flow-root mb-5 pb-5">
              <ul class="-mb-8">
                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-white" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>

                      <div class="
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">Votes</p>
                        </div>
                        <div class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          {{ coin.votes }}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-white" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>

                      <div class="
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">Chain</p>
                        </div>
                        <div class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          {{ coin.network }}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-white" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>

                      <div class="
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">Launched</p>
                        </div>
                        <div class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          {{ moment(coin.launched) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-white" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>

                      <div class="
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">Added</p>
                        </div>
                        <div class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          {{ moment(coin.added) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-white" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>

                      <div class="
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">Status</p>
                        </div>
                        <div class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          {{ coin.status }}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="pb-5 mb-5 pb-5">
            <h2 id="details-title" class="text-lg font-bold text-gray-900 dark:text-white">
              Information
            </h2>

            <!-- Activity Feed -->
            <div class="mt-6 flow-root">
              <ul class="-mb-8">
                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-white" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>

                      <div class="
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">
                            Price (USD)
                          </p>
                        </div>
                        <div class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          $0.000000
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-white" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>

                      <div class="
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">
                            Market Cap
                          </p>
                        </div>
                        <div class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          {{ coin.capital }}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-white" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>

                      <div class="
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">Volume</p>
                        </div>
                        <div class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          $0.000000
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="mb-5">
            <h2 id="details-title" class="font-bold text-lg font-bold text-gray-900 dark:text-white">
              Links
            </h2>

            <!-- Activity Feed -->
            <div class="mt-6 flow-root">
              <ul class="-mb-8">
                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-white" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                        </svg>
                      </span>

                      <div class="
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">Website</p>
                        </div>
                        <div class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          <a class="
                              text-green-500
                              hover:underline
                              font-bold
                              capitalize
                            " :href="coin.website" target="_blank" rel="nofollow">
                            {{ coin.website.replace("https://", "") }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center h-6 w-6">
                        <i class="fa fa-chart-line dark:text-white text-xl"></i>
                      </span>

                      <div class="
                          flex
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                          items-center
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">Charts</p>
                        </div>
                        <div class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          <div class="inline-block">
                            <a class="
                                text-green-500
                                hover:underline
                                font-bold
                                mr-2
                              " :href="
                                'https://poocoin.app/tokens/' + coin.contract
                              " target="_blank" rel="nofollow">
                              Poocoin.app
                            </a>
                            <a class="
                                text-green-500
                                hover:underline
                                font-bold
                                mr-2
                              " :href="
                                'https://charts.bogged.finance/' + coin.contract
                              " target="_blank" rel="nofollow">
                              Bogged.finance
                            </a>
                            <!-- 
                         <slot v-if="coin.network.indexOf('BSC')"> 
                         <a class="text-green-500 hover:underline font-bold mr-2" :href="'https://dex.guru/token/' + coin.contract + '-bsc'" target="_blank" rel="nofollow">
                         Dex.guru </a>
                           <a class="text-green-500 hover:underline font-bold" :href="'https://www.dextools.io/app/pancakeswap/pair-explorer/' + coin.contract" target="_blank" rel="nofollow">
                         Dextools.io </a>
                         </slot>
                         -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center h-6 w-6">
                        <i class="fa fa-sync-alt dark:text-white text-xl"></i>
                      </span>

                      <div class="
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">Buy</p>
                        </div>
                        <div class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          <a class="text-green-500 hover:underline font-bold" :href="
                              'https://exchange.pancakeswap.finance/#/swap?outputCurrency=' +
                                coin.contract
                            " target="_blank" rel="nofollow">
                            PancakeSwap
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center h-6 w-6">
                        <i class="fa fa-eye dark:text-white text-xl"></i>
                      </span>

                      <div class="
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">
                            Block Explorer
                          </p>
                        </div>
                        <div v-if="coin.network.indexOf('BSC') || coin.network == 'BSC'" class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          <a class="text-green-500 hover:underline font-bold"
                            :href="'https://bscscan.com/token/' + coin.contract" target="_blank" rel="nofollow">
                            BSCScan
                          </a>
                        </div>
                        <div v-else class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          <a class="text-green-500 hover:underline font-bold" :href="
                              'https://etherscan.com/token/' + coin.contract
                            " target="_blank" rel="nofollow">
                            EtherScan
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center h-6 w-6">
                        <i class="fab fa-telegram dark:text-white text-xl"></i>
                      </span>

                      <div class="
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">Telegram</p>
                        </div>
                        <div class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          <a class="text-green-500 hover:underline font-bold" :href="coin.telegram" target="_blank"
                            rel="nofollow">
                            Join Telegram
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center h-6 w-6">
                        <i class="fab fa-twitter dark:text-white text-xl"></i>
                      </span>

                      <div class="
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">Twitter</p>
                        </div>
                        <div class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          <a class="text-green-500 hover:underline font-bold" :href="coin.twitter" target="_blank">
                            Follow Twitter
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="relative pb-8">
                    <div class="relative flex space-x-3 items-center">
                      <span class="flex items-center justify-center h-6 w-6">
                        <i class="fab fa-discord dark:text-white text-xl"></i>
                      </span>

                      <div class="
                          min-w-0
                          flex-1
                          pt-1.5
                          flex
                          justify-between
                          space-x-4
                        ">
                        <div>
                          <p class="text-gray-900 dark:text-white">Discord</p>
                        </div>
                        <div class="
                            text-right text-gray-900
                            dark:text-white
                            font-bold
                          ">
                          <a class="text-green-500 hover:underline font-bold" :href="coin.discord" target="_blank">
                            Join Discord
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>

      <Recaptcha :showRecaptcha="recaptcha" @recaptchaVerified="recaptchaVerified" />

      <div class="max-w-7xl mx-auto">
        <Promoted />
      </div>
    </main>
    <main v-else class="main flex items-center justify-center">
      <div class="text-4xl font-extrabold text-center text-green-500">
        Loading...
      </div>
    </main>
  </div>
</template>

<script>
import { computed, ref } from "vue";
  import {
    mapGetters
  } from "vuex";
  import {
    utils
  } from "../utils/utils";
  import {
    clean
  } from "@/utils/helpers";
  import Recaptcha from "../components/Recaptcha/Recaptcha.vue";
  import ShareButtons from "../components/Parts/ShareButtons.vue";
  import Promoted from "../components/Cards/Promoted.vue";
  import moment from "moment";
  import { useMeta } from "vue-meta";

  export default {
    name: "Coin",
    components: {
      Recaptcha,
      Promoted,
      ShareButtons,
    },
    data() {
      return {
      
        recaptcha: false,
      };
    },
    computed: {
      ...mapGetters(["coinsData", "verifiedCoins"]),
      coin() {
        return this.coinsData.filter((coin) => {
          /* if (coin.id === this.$route.params.coinId) {
            return coin;
          } */
          if (clean(coin.fullname) === this.$route.params.coinId) {
            return coin;
          }
        })[0];
      },

    },
setup() {
 
   useMeta({
      title:  "gggggggggggggggggg",
      htmlAttrs: { lang: 'en', amp: true }
    })

  },
    methods: {
      async countVote() {
        this.$store.dispatch("COUNT_VOTE", {
          id: this.coin.id,
          vm: this,
        });
      },
      async recaptchaVerified(token) {
        let response = await utils.verifySite(token);
        if (response) {
          this.recaptcha = false;
          this.countVote();
        }
      },
      momentFromNow: function(date) {
        return moment().diff(date, "days") + " days";
      },
      moment: function(date) {
        return moment(date).format("MMMM Do YYYY");
      },
      clean,
    },
  };
</script>

<style>
  #coin {
    position: relative;
  }
  .fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>